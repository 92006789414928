import React from "react";
import Classes from "./data.module.scss";
import Varified from "../../assets/images/icons/varified.svg";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

const LeaderBoardData = ({ friend }) => {
  const navigate = useNavigate();

  return (
    <div className={Classes.columnContainer}>
      <div className={Classes.column}>
        <div className={Classes.item}>
          <div
            className="d-flex align-items-center gap-4 pb-4"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/user/${friend?.user?.username}`)}
          >
            {/* <span className={Classes.number}>1</span> */}
            <div className="position-relative">
              <img
                className={Classes.userImg}
                src={friend?.user?.profilePicture}
                alt="img"
              />
              <img className={Classes.varified} src={Varified} alt="img" />
            </div>
            <div>
              <h3
                className={Classes.title}
              >{`${friend?.user?.role === "organization" ? friend?.user?.nonProfitName : friend?.user?.username}`}</h3>
              {friend?.type && friend?.fiatAmount && (
                <div className="d-flex align-items-center gap-2">
                  <span className={Classes.subTxt}>Charity Donations:</span>
                  <div className="d-flex gap-50">
                    <span className={Classes.subTxt}>
                      {friend?.price.toFixed(8)}
                    </span>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center gap-2">
                <span className={Classes.subTxt}>Shared Causes</span>
                <div className="d-flex gap-50">
                  {friend?.user?.categories.slice(0, 8).map((item, key) => (
                    <Tooltip
                      placement="top"
                      title={item?.categoryName}
                      key={key}
                    >
                      <img
                        className={Classes.friendImg}
                        src={item?.categoryImage}
                        alt="img"
                      />
                    </Tooltip>
                  ))}
                  {friend?.user?.categories.length > 8 && (
                    <Tooltip
                      placement="top"
                      title={`+${friend?.user?.categories.length - 8} more`}
                    >
                      <div className={Classes.plusIcon}>
                        +{friend?.user?.categories.length - 8}
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              {/* <div className='d-flex align-items-center gap-2'>
                <span className={Classes.subTxt}>Floor Price</span>
                <div className='d-flex gap-2'>
                  <span className={Classes.price}>180</span>
                  <img src={Polygon} alt='img' />
                </div>
              </div> */}
            </div>
          </div>
          {/* <div>
            <span className={Classes.amount}>+ 45,23%</span>
            <div className="d-flex gap-2">
              <span className={Classes.polygonTxt}>5,334,54</span>
              <img src={Polygon} alt="img" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardData;
