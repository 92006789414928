import React, { Suspense } from 'react';
import Cause from '../../Cause';
import New from '../../New';
import PopularNft from '../../PopularNft';

// Lazy load below-the-fold components
const ComingSoon = React.lazy(() => import('../../ComingSoon'));
const LeaderBoard = React.lazy(() => import('../../LeaderBoard'));

// Simple loading component
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center p-4">
    <div>Loading...</div>
  </div>
);

const GuestHome = () => {
  console.log("GuestHome")
  return (
    <>
      {/* Critical components load immediately */}
      <Cause />
      <New />
      <PopularNft />
      
      {/* Below-the-fold components lazy loaded */}
      <Suspense fallback={<LoadingSpinner />}>
        <ComingSoon />
        <LeaderBoard />
      </Suspense>
    </>
  );
};

export default GuestHome;
