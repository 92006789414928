// @ts-ignore
import {
  AUTH,
  LOGOUT,
  SET_ORGANIZATIONS,
  SET_WALLET_EMPTY,
  UPDATE_SOCIAL_MEDIA,
  UPDATE_NOTIFICATION_SETTINGS,
} from "../constants";
import { getRequest } from "../../helpers/request";
import { ENV } from "../../config";
const login = ({
  id,
  walletAddress,
  nonProfitName,
  einNumber,
  email,
  emailVerified,
  firstName,
  lastName,
  username,
  password,
  address,
  addressTwo,
  city,
  state,
  zipCode,
  country,
  is2faEnabled,
  role,
  profilePicture,
  bannerImage,
  bio,
  missionStatement,
  favourites,
  friends,
  defaultSponsorshipFee,
  socialMedia,
  notificationIds,
  isoDocument,
  isoGuideStarEmail,
  organizationType,
  isIsoVerified,
  categories,
  createdAt,
  fiscalProjects,
  fiscalSponsorId,
  verificationStatus,
}) => {
  return {
    type: AUTH,
    payload: {
      id,
      walletAddress,
      nonProfitName,
      einNumber,
      email,
      emailVerified,
      firstName,
      lastName,
      username,
      password,
      address,
      addressTwo,
      city,
      state,
      zipCode,
      country,
      is2faEnabled,
      role,
      profilePicture,
      bannerImage,
      bio,
      missionStatement,
      favourites,
      friends,
      defaultSponsorshipFee,
      socialMedia,
      notificationIds,
      isoDocument,
      isoGuideStarEmail,
      organizationType,
      isIsoVerified,
      categories,
      createdAt,
      fiscalProjects,
      fiscalSponsorId,
      verificationStatus
    },
  };
};

const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const emptyWallet = () => {
  return {
    type: SET_WALLET_EMPTY,
  };
};

export const thunkLogin = (data, callback) => {
  return async (dispatch) => {
    try {
      await dispatch(
        login({
          id: data?._id,
          walletAddress: data?.walletAddress,
          nonProfitName: data?.nonProfitName,
          einNumber: data?.einNumber,
          email: data?.email,
          emailVerified: data?.emailVerified,
          firstName: data?.firstName,
          lastName: data?.lastName,
          username: data?.username,
          password: data?.password,
          address: data?.address,
          addressTwo: data?.addressTwo,
          city: data?.city,
          state: data?.state,
          zipCode: data?.zipCode,
          country: data?.country,
          is2faEnabled: data?.is2faEnabled,
          role: data?.role,
          profilePicture: data?.profilePicture,
          bannerImage: data?.bannerImage,
          favourites: data?.favourites,
          friends: data?.friends,
          defaultSponsorshipFee: data?.defaultSponsorshipFee,
          socialMedia: data?.socialMedia[0] || "",
          notificationIds: data?.notificationSettings,
          bio: data?.bio,
          missionStatement: data?.missionStatement,
          isoDocument: data?.isoDocument,
          fiscalProjects: data?.fiscalProjects,
          fiscalSponsorId: data?.fiscalSponsorId,
          isoGuideStarEmail: data?.isoGuideStarEmail,
          organizationType: data?.organizationType,
          isIsoVerified: data?.isIsoVerified,
          categories: data?.categories,
          createdAt: data?.createdAt,
          verificationStatus:data?.verificationStatus
        })
      );
      callback();
    } catch (error) {
      console.log("Error: ", error.message);
      console.log("Error: ", error);
    }
  };
};

export const thunkLogout = (callback) => {
  return async (dispatch) => {
    try {
      localStorage.removeItem("authenticationToken");
      localStorage.removeItem("walletAddress");
      localStorage.removeItem("activeWallet");
      localStorage.removeItem("userInfo");

      await dispatch(logout());
      await dispatch(emptyWallet());
    } catch (error) {
      console.log("Logout Error: ", error);
    }
  };
};
export const fetchOrganizationUsers = () => (dispatch) => {
  let url = ENV.apiBaseUrl + "/auth/users/organizations";
  // dispatch({ type: SHOW_NFTS_LIST_LOADER });
  getRequest(url)
    .then((res) => {
      console.log(res, "res of organization");
      dispatch({ type: SET_ORGANIZATIONS, payload: res.data });
      // dispatch({ type: HIDE_NFTS_LIST_LOADER });
    })
    .catch((err) => {
      console.log(err, "err=>");
      // dispatch({ type: HIDE_NFTS_LIST_LOADER });
    });
};

export const updateSocialMedia = (socialMedia) => {
  return {
    type: UPDATE_SOCIAL_MEDIA,
    payload: socialMedia,
  };
};

export const updateNotificationSettings = (notificationIds) => {
  return {
    type: UPDATE_NOTIFICATION_SETTINGS,
    payload: notificationIds,
  };
};
// export const countRequestsUpdated = (count) => {
//   return {
//     type: COUNT_REQUESTS,
//     payload: count,
//   };
// };
// export const getManagersList = (managers) => {
//   return {
//     type: GET_MANAGERS_LIST,
//     payload: managers,
//   };
// };

// const permissionUpdated = ({ systemPermissions }) => {
//   return {
//     type: PERMISSIONS_UPDATED,
//     payload: {
//       systemPermissions,
//     },
//   };
// };
// export const userUpdated = (data) => {
//   return {
//     type: USER_UPDATED,
//     payload: data,
//   };
// };
// export const profileUpdated = (data) => {
//   return {
//     type: PROFILE_UPDATED,
//     payload: data,
//   };
// };

// export const thunkPermissionUpdated = (data: any): any => {
//   return async (dispatch) => {
//     try {
//       await dispatch(
//         permissionUpdated({
//           systemPermissions: data,
//         })
//       );
//     } catch (error) {
//       console.log('Error: ', error.message);
//     }
//   };
// };

// export const thunkUserUpdate = (data, callback) => {
//   return async (dispatch) => {
//     try {
//       // const token = localStorage.getItem('accessToken');
//       await dispatch(
//         login({
//           id: data?.id,
//           firstName: data?.firstName,
//           lastName: data?.lastName,
//           companies: data?.companies,
//           email: data?.email,
//           authKey: data?.authKey,
//           status: data?.status,
//           token: data?.token,
//           avatar: data?.avatar,
//           systemPermissions: data?.systemPermissions,
//           settings: data?.settings,
//           isTwoFactorAuthenticationEnabled:
//             data?.isTwoFactorAuthenticationEnabled,
//         })
//       );
//       callback();
//     } catch (error) {
//       console.log('Error: ', error.message);
//       console.log('Error: ', error);
//     }
//   };
// };

// export const thunkUserSessionUpdate = (data, callback) => {
//   return async (dispatch) => {
//     try {
//       await dispatch(
//         login({
//           id: data?.id,
//           firstName: data?.firstName,
//           lastName: data?.lastName,
//           companies: data?.companies,
//           email: data?.email,
//           authKey: data?.authKey,
//           status: data?.status,
//           token: data?.token,
//           avatar: data?.avatar,
//           systemPermissions: data?.systemPermissions,
//           settings: data?.settings,
//           isTwoFactorAuthenticationEnabled:
//             data?.isTwoFactorAuthenticationEnabled,
//         })
//       );
//       callback();
//     } catch (error) {
//       console.log('Error: ', error.message);
//       console.log('Error: ', error);
//     }
//   };
// };
