import React, { useEffect, useState, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import LandingLayout from '../../layout/LandingLayout';
import { getNFT } from '../../../store/_actions/nft_actions';

// Lazy load components
const NFTDetail = React.lazy(() => import('../../NFTDetail'));
const Offers = React.lazy(() => import('../../Offers'));
const NftInformation = React.lazy(() => import('../../NftInformation'));
const MoreItems = React.lazy(() => import('../../MoreItems'));
const OffersTable = React.lazy(() => import('../../OffersTable'));
const ListingTable = React.lazy(() => import('../../ListingTable'));

const ViewNft = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { nft } = useSelector((state) => state?.nft);
  const { bids } = useSelector((state) => state?.bid);
  const [activeAuction,setActiveAuction]=useState(null)
  const [price,setPrice]=useState()
  const location = useLocation();

  useEffect(() => {
    dispatch({ type: 'RESET_NFT_DATA' }); // to avoid moreLikeThis view & back behavior of data embedding in state

    fetchData();
    
  }, [id, dispatch]);

  const fetchData = () => {
    dispatch(getNFT(id));
  };
  // const { totalListings } = nft && nft?.nft;  destructuring
  const { totalListings } = nft?.nft || {};

 
useEffect(()=>{
  const foundListedAuction =
  location?.state?.auctionId && totalListings?.length > 0
    ? totalListings?.find((item) => item._id === location?.state?.auctionId)
    : null;

const auction = foundListedAuction
  ? foundListedAuction
  : totalListings?.length > 0
  ? totalListings[0]
  : null;

const auctionPrice =
  auction?.sellingMethod === 1
    ? auction?.sellingConfig?.price.amount
    : auction?.sellingMethod === 2
    ? auction?.sellingConfig?.startPrice?.amount
    : 0;
    setActiveAuction(auction)
    setPrice(auctionPrice)
},[totalListings,location?.state?.auctionId])
  return (
    <LandingLayout>
      <Container className='pt-5'>
        {!nft?.nft || Object.keys(nft?.nft).length === 0 ? (
          <LoadingSpinner />
        ) : (
          <>
            {nft?.nft && (
              <Suspense fallback={<LoadingSpinner />}>
                <NFTDetail
                  data={nft?.nft}
                  offers={bids}
                  priceData={nft?.nft?.priceHistory}
                  fetchData={fetchData}
                  activeAuction={activeAuction}
                  price={price}
                />
              </Suspense>
            )}
            <Row gutter={[25, 25]}>
              <Col lg={10} md={24} sm={24} xs={24}>
                {nft?.nft && (
                  <Suspense fallback={<LoadingSpinner />}>
                    <NftInformation data={nft?.nft} />
                  </Suspense>
                )}
              </Col>
              <Col lg={14} md={24} sm={24} xs={24}>
                <Suspense fallback={<LoadingSpinner />}>
                  <ListingTable listings={nft?.nft.totalListings} />
                  <OffersTable activity={nft?.activity} />
                  {bids && activeAuction?.sellingMethod === 2 && (
                    <Offers offers={bids} data={nft?.nft} />
                  )}
                </Suspense>
              </Col>
            </Row>
            {nft?.moreLikeThis && (
              <Suspense fallback={<LoadingSpinner />}>
                <MoreItems key={id} data={nft?.moreLikeThis} />
              </Suspense>
            )}
          </>
        )}
      </Container>
    </LandingLayout>
  );
};

// Loading spinner component for reuse
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center align-items-center">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#d9d9d9"
      ariaLabel="three-dots-loading"
      visible={true}
    />
  </div>
);

export default ViewNft;
