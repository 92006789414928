// validation.js
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  nonProfitName: Yup.string()
    .trim()
    .max(60, ({ max }) => `Nonprofit should not exceed ${max} characters.`)
    .min(4, ({ min }) => `Nonprofit should be at least ${min} characters.`)
    .required("Nonprofit name is required."),
  // sponsorshipFee: Yup.string().trim().required('Sponsorship fee is required.'),
  firstName: Yup.string()
    .trim()
    .max(20, ({ max }) => `First name should not exceed ${max} characters.`)
    .min(1, ({ min }) => `First name should have at least ${min} characters.`)
    .matches(/[a-zA-Z]/, "First name must contain at least one alphabetical character.")
    .required("First name is required."),
  lastName: Yup.string()
    .trim()
    .max(20, ({ max }) => `Last name should not exceed ${max} characters.`)
    .min(1, ({ min }) => `Last name should have at least ${min} characters.`)
    .matches(/[a-zA-Z]/, "Last name must contain at least one alphabetical character.")
    .required("Last name is required."),
  username: Yup.string()
    .matches(
      /^[a-zA-Z0-9_]+$/,
      "Username must contain only letters, numbers, and underscores."
    )
    .test(
      "no-whitespace",
      "Username cannot contain white spaces",
      (value) => !/\s/.test(value)
    )
    .max(20, ({ max }) => `Username should not exceed ${max} characters.`)
    .min(4, ({ min }) => ` Username should have at least ${min} characters.`)
    .required("Username is required."),
  address: Yup.string()
    .trim()
    .max(50, ({ max }) => `Address should not exceed ${max} characters.`)
    .required("Address is required."),
  addressTwo: Yup.string()
    .trim()
    .max(50, ({ max }) => `Address Line2 should not exceed ${max} characters.`)
    .optional(),
  city: Yup.string()
    .trim()
    // .max(20, ({ max }) => `City should not exceed ${max} characters.`)
    // .min(2, ({ min }) => `City should have at least ${min} characters.`)
    .required("City is required."),
  zipCode: Yup.string()
    .trim()
    .max(10, ({ max }) => `Zip code should not exceed ${max} digits.`)
    .min(4, ({ min }) => `Zip code should have at least ${min} characters.`)
    .required("Zip code is required."),
  state: Yup.string()
    .trim()
    // .max(20, ({ max }) => `State should not exceed ${max} characters.`)
    // .min(2, ({ min }) => `State should have at least ${min} characters.`)
    .required("State is required."),
  country: Yup.string()
    .trim()
    // .max(20, ({ max }) => `Country should not exceed ${max} characters.`)
    // .min(2, ({ min }) => `Country should have at least ${min} characters.`)
    .required("Country is required."),
  missionStatement: Yup.string()
    .trim()
    .min(50, ({ min }) => `Mission statement should have at least ${min} characters.`)
    .max(600, "Maximum 600 characters allowed")
    .required("Mission statement is required."),
  facebook: Yup.string().optional().url("Please enter a valid URL"),
  instagram: Yup.string().optional().url("Please enter a valid URL"),
  linkedIn: Yup.string().optional().url("Please enter a valid URL"),
  website: Yup.string().optional().url("Please enter a valid URL"),


  //   file: Yup.string().trim().required('file is required.'),
});

export const validateForm = (values) => {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return { validationErrors: {} }; // No errors
  } catch (errors) {
    return {
      validationErrors: errors.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {}),
    };
  }
};
