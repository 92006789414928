import React, { Fragment, useState, useEffect } from "react";
import Classes from "../../components/views/Pages/scss/fiscal.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker, Row, Col } from "antd";
import Select2 from "react-select";
import Banner from "../../assets/images/section/ifers-banner.png";
import * as Yup from "yup";
import { Formik } from "formik";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import DefaultFiscalSponsorModal from "./DefaultFiscalSponsorModal";
import countriesData from '../../helpers/countries.json';
import statesData from '../../helpers/states.json';
import citiesData from '../../helpers/cities.json';

const FiscalSponsorAgreement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);
  const currentDate = moment();
  const [isModalDefaultSponsor, setsModalDefaultSponsor] = useState(false);
  const [data, setData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const handleExtractNames = (name) => {
    const words = name.split(" ");
    const lastName = words.pop(); // Get and remove the last word
    const firstName = words.join(" "); // Join the remaining words as first name
    return { firstName, lastName };
  };

  const handleFiscalSponsorReject = async () => {
    // handleAgreementCancel();
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleAgreementForm = (values) => {
    const { firstName, lastName } = handleExtractNames(
      values?.nonprofitDirectorName
    );
    const fiscalSponsorAgreementData = {
      fiscalSponsorNonProfitName:
        "Institute for Education, Research, and Scholarships",
      fiscalSponsorEmail: location?.state?.data?.fiscalSponsorEmail, //'ifers@getnada.com',
      nonprofitName: values?.nonprofitName,
      website: values?.website,
      facebook: values?.facebook,
      linkedIn: values?.linkedIn,
      instagram: values?.instagram,
      contactNumber: values?.contactNumber,
      address: values?.address,
      city: selectedCity,
      zipCode: values?.zipCode,
      state: selectedState,
      country: selectedCountry,
      firstName: firstName,
      lastName: lastName,
      accountType: location?.state?.data?.accountType,
      organizationType: location?.state?.data?.organizationType,
      role: location?.state?.data?.role,
      fiscalProjects: location?.state?.data?.fiscalProjects,
      fiscalSponsorId: location?.state?.data?.fiscalSponsorId,
      missionStatement:values?.missionStatement
    };
    setsModalDefaultSponsor(true);
    setData(fiscalSponsorAgreementData);

    // navigate('/build-organization-profile', {
    //   state: { fiscalSponsorAgreementData },
    // });
  };
  const handleKeyPress =(event)=>{
    if(event.key==='Enter'){
      event.preventDefault();
      event.stopPropagation();
    }
  }

  useEffect(() => {
    // Load countries from the JSON file
    setCountries(countriesData.map(country => ({ value: country.id, label: country.name })));
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Filter states based on selected country
      const filteredStates = statesData
        .filter(state => state.country_id === selectedCountry.value)
        .map(state => ({ value: state.id, label: state.name }));
      setStates(filteredStates);
      setCities([]); // Reset cities when country changes
      setSelectedState(null); // Reset selected state
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Filter cities based on selected state
      const filteredCities = citiesData
        .filter(city => city.state_id === selectedState.value)
        .map(city => ({ value: city.id, label: city.name }));
      setCities(filteredCities);
      setSelectedCity(null); // Reset selected city
    }
  }, [selectedState]);
  return (
    <>
      <div className={`${Classes.mainOuter}`}>
        <Container>
          <div className={Classes.content}>
            <img className={Classes.banner} src={Banner} alt="banner" />
            <div className={`${Classes.infoTxt} mb-4`}>
              <h1>
                Institute for Education, Research, and Scholarships (IFERS)
              </h1>
              <p>
                An Award-Winning 501(c)(3) nonprofit public charity
                organization, est. 2004
              </p>
              <p>811 West 7th St, Suite 1200, Los Angeles, CA 90017, USA</p>
              <p>www.ifers.org +1 323-825-2637</p>
            </div>

            <div className={`${Classes.agreement} my-4`}>
              <h1 className={`${Classes.title} text-center`}>
                FISCAL SPONSORSHIP APPLICATION
              </h1>
              <p className="mb-4">
                This Fiscal Sponsorship Application (referred to herein as “the
                Application”) is made by and between the 501(c)(3) Institute for
                Education, Research, and Scholarships (IFERS) (referred to
                herein as “the Sponsor”) and the nonprofit named at the bottom
                of this document (referred to herein as “the Project”) on the
                date specified at the bottom of this document.
              </p>
              <div>
                <div className="mb-3">
                  <b>The Sponsor:&nbsp;</b>
                  <span>
                    The Sponsor is a nonprofit corporation, exempt from federal
                    tax under section 501(c)(3) of the Internal Revenue Code. It
                    is formed for purposes which, according to its bylaws, shall
                    be organized and operated exclusively for charitable,
                    scientific, and educational purposes.
                  </span>
                </div>
                <div className="mb-3">
                  <b>The Project:&nbsp;</b>
                  <span>
                    The Project is an organization formed to fulfill the
                    expressed mission of charitable, scientific, and/or
                    educational purposes.
                  </span>
                </div>
                <div className="mb-3">
                  <b>The Application:&nbsp;</b>
                  <span>
                    The Project seeks to become a fiscal project of the Sponsor
                    in order to implement the Project’s purposes as a nonprofit
                    entity, exempt from federal tax under section 501(c)(3) of
                    the Internal Revenue Code.
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-2 mb-3">
              <p className={Classes.desc}>Date of Application:</p>
              <DatePicker
                onChange={onChange}
                defaultValue={currentDate}
                disabled
              />
              <p className="mt-3 mb-1">
                <b>Sign-Up Email:</b>
                {
                  <Row gutter={[20, 20]}>
                    <Col md={8} sm={24}>
                      <input
                        type="text"
                        value={
                          JSON.parse(localStorage.getItem("userInfo"))?.email ||
                          ""
                        }
                        disabled={true}
                        className={Classes.input}
                      />
                    </Col>
                  </Row>
                }
              </p>
            </div>

            <Formik
              initialValues={{
                nonprofitDirectorName: "",
                nonprofitName: "",
                website: "",
                email: auth?.email || "",
                contactNumber: "",
                address: "",
                city: "",
                zipCode: "",
                state: "",
                country: "",
                facebook: "",
                linkedIn: "",
                instagram: "",
                missionStatement:""
              }}
              validationSchema={Yup.object().shape({
                nonprofitDirectorName: Yup.string()
                  .trim()
                 .min(4, ({ min }) => `Nonprofit director name should have at least ${min} characters.`)
                .max(25, ({ max }) => `Nonprofit director name should not exceed ${max} characters.`)
                  .required("Nonprofit director name is required."),
                nonprofitName: Yup.string()
                  .trim()
                 .min(4, ({ min }) => `Nonprofit name should have at least ${min} characters.`)
                .max(60, ({ max }) => `Nonprofit name should not exceed ${max} characters.`)
                  .required("Nonprofit Name is required."),
                email: Yup.string()
                  .email("Email is invalid.")
                  .trim()
                  .max(50, "Email must be at most 20 characters.")
                  .required("Email is required."),
                contactNumber: Yup.string()
                  .trim()
                .max(20, ({ max }) => `Contact number should not exceed ${max} characters.`)
                  .required("Contact number is required."),
                address: Yup.string()
                .trim()
                .max(50, ({ max }) => `Address should not exceed ${max} characters.`)
                .required("Address is required."),
                city: Yup.string()
                .trim()
                //  .min(2, ({ min }) => `City should have at least ${min} characters.`)
                //  .max(20, ({ max }) => `City should not exceed ${max} characters.`)
                .required("City is required."),
                zipCode: Yup.string()
                .trim()
                .min(4, ({ min }) => `Zip code should have at least ${min} characters.`)
                .max(10, ({ max }) => `Zip code should not exceed ${max} digits.`)
                .required("Zip code is required."),
                state: Yup.string()
                .trim()
                //  .min(2, ({ min }) => `State should have at least ${min} characters.`)
                // .max(20, ({ max }) => `State should not exceed ${max} characters.`)

                .required("State is required."),
                country: Yup.string()
                .trim()
                // .min(2, ({ min }) => `Country should have at least ${min} characters.`)
                // .max(20, ({ max }) => `Country should not exceed ${max} characters.`)
                .required("Country is required."),
                missionStatement: Yup.string()
                .trim()
                .min(50, ({ min }) => `Mission statement should have at least ${min} characters.`)
                .max(600, "Maximum 600 characters allowed").required("Mission statement is required."),
                facebook: Yup.string().optional().url("Please enter a valid URL"),
                instagram: Yup.string().optional().url("Please enter a valid URL"),
                linkedIn: Yup.string().optional().url("Please enter a valid URL"),
                website: Yup.string().optional().url("Please enter a valid URL"),
              })}
              onSubmit={(values, { resetForm }) => {
                handleAgreementForm(values, resetForm);
              }}
              textAlign="center"
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                handleSubmit,
                touched,
                values,
              }) => (
                <>
                  <p className="mb-2">
                    <b>PROJECT DETAILS:</b>
                  </p>
                  {console.log(errors,'errors=>')}
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="pb-5">
                      <Row gutter={[20, 20]}>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>
                            Nonprofit Director Name*
                          </p>
                          <input
                            type="text"
                            name="nonprofitDirectorName"
                            placeholder={"Nonprofit Director Name"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.nonprofitDirectorName}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.nonprofitDirectorName &&
                            touched.nonprofitDirectorName ? (
                              <Fragment>
                                {errors.nonprofitDirectorName}
                              </Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Nonprofit Name*</p>
                          <input
                            type="text"
                            name="nonprofitName"
                            placeholder={"Nonprofit Name"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.nonprofitName}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.nonprofitName && touched.nonprofitName ? (
                              <Fragment>{errors.nonprofitName}</Fragment>
                            ) : null}
                          </span>
                        </Col>

                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Email*</p>
                          <input
                            type="text"
                            name="email"
                            placeholder={"Email address"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.email}
                            className={Classes.input}
                            disabled
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.email && touched.email ? (
                              <Fragment>{errors.email}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Contact Number*</p>
                          <input
                            type="text"
                            name="contactNumber"
                            placeholder={"Contact Number"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.contactNumber}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.contactNumber && touched.contactNumber ? (
                              <Fragment>{errors.contactNumber}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Address*</p>
                          <input
                            type="text"
                            name="address"
                            placeholder={"Address"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.address}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.address && touched.address ? (
                              <Fragment>{errors.address}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Zip Code*</p>
                          <input
                            type="text"
                            name="zipCode"
                            placeholder={"Zip Code"}
                            onBlur={handleBlur}
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            value={values.zipCode}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.zipCode && touched.zipCode ? (
                              <Fragment>{errors.zipCode}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Country*</p>
                          <Select2
                    className={Classes.selectInput}
                    value={selectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setFieldValue("country", option ? option.label : "");
                      setSelectedState(null);
                      setFieldValue("state", "");
                      setSelectedCity(null);
                      setFieldValue("city", "");
                    }}
                    options={countries}
                    placeholder="Select Country"
                  />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.country && touched.country ? (
                              <Fragment>{errors.country}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>State*</p>
                          <Select2
                    className={Classes.selectInput}
                    value={selectedState}
                    onChange={(option) => {
                      setSelectedState(option);
                      setFieldValue("state", option ? option.label : "");
                      setSelectedCity(null);
                      setFieldValue("city", "");
                    }}
                    options={states}
                    placeholder="Select State"
                    isDisabled={!selectedCountry}
                  />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.state && touched.state ? (
                              <Fragment>{errors.state}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>City*</p>
                          <Select2
                    className={Classes.selectInput}
                    value={selectedCity}
                    onChange={(option) => {
                      setSelectedCity(option);
                      setFieldValue("city", option ? option.label : "");
                    }}
                    options={cities}
                    placeholder="Select City"
                    isDisabled={!selectedState}
                  />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors.city && touched.city ? (
                              <Fragment>{errors.city}</Fragment>
                            ) : null}
                          </span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Website</p>
                          <input
                            type="text"
                            name="website"
                            placeholder={"Website"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.website}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >{errors.website && touched.website ? (
                            <Fragment>{errors.website}</Fragment>
                          ) : null}</span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>LinkedIn</p>
                          <input
                            type="text"
                            name="linkedIn"
                            placeholder={"LinkedIn"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.linkedIn}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >{errors.linkedIn && touched.linkedIn ? (
                            <Fragment>{errors.linkedIn}</Fragment>
                          ) : null}</span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Facebook</p>
                          <input
                            type="text"
                            name="facebook"
                            placeholder={"Facebook"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.facebook}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >{errors.facebook && touched.facebook ? (
                            <Fragment>{errors.facebook}</Fragment>
                          ) : null}</span>
                        </Col>
                        <Col md={8} sm={24}>
                          <p className={Classes.desc}>Instagram</p>
                          <input
                            type="text"
                            name="instagram"
                            placeholder={"Instagram"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={values.instagram}
                            className={Classes.input}
                          />
                          <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >{errors.instagram && touched.instagram ? (
                            <Fragment>{errors.instagram}</Fragment>
                          ) : null}</span>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                          <div>
                            <p className={Classes.desc}>Mission Statement (50 to 600 characters)*</p>
                            <input
                              type="text"
                              name="missionStatement"
                              // placeholder={"Mission Statement"}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onKeyPress={handleKeyPress}
                              value={values?.missionStatement}
                              className={Classes.missionStatementInput}
                            />
                             <span
                            className="mt-1 d-block"
                            size={"small"}
                            style={{ color: "red" }}
                          >
                            {errors?.missionStatement ? (
                              <Fragment>{errors.missionStatement}</Fragment>
                            ) : null}
                          </span>
                          </div>
                          {/* <ErrorMessage
                            name="missionStatement"
                            render={(msg) => (
                              <span
                                className="mt-1 d-block"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </span>
                            )}
                          /> */}
                        </Col>
                      </Row>
                    </div>

                    <div className={Classes.footer}>
                      <Container>
                        <div className={Classes.btnOuter}>
                          <button
                            onClick={() => navigate("/user-info")}
                            className={`${Classes.backBtn} ${Classes.blueBtn}`}
                          >
                            Back
                          </button>
                          <button
                            className={`${Classes.blueBtn}`}
                            type="submit"
                          >
                            Next
                          </button>
                        </div>
                      </Container>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </Container>
      </div>
      <DefaultFiscalSponsorModal
        isModalDefaultSponsor={isModalDefaultSponsor}
        data={data}
        handleConsentCancel={() => setsModalDefaultSponsor(false)}
      />
    </>
  );
};

export default FiscalSponsorAgreement;
