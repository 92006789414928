import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Col, Row, Tooltip } from "antd";
import Classes from "./heroSection.module.scss";
import { Container } from "react-bootstrap";
import PlayBtn from "../../assets/images/icons/playBtn.svg";
import PauseBtn from "../../assets/images/icons/pauseBtn.svg";
import Slide1img from "../../assets/images/section/slider1img.png";
import Slide3img from "../../assets/images/section/slider3img.svg";
import Slide4img from "../../assets/images/section/slider4img.svg";
import Slide5img from "../../assets/images/section/slider5img.svg";
import Slide6img from "../../assets/images/section/slider6img.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function HeroSection() {
  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [show, setShow] = useState(false);
  const [slider, setSlider] = useState("all");
  const auth = useSelector((state) => state.auth);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: isPlaying,
    autoplaySpeed: 2000,
    arrows: false,
    fade: true,
    lazyLoad: "ondemand",
  };

  const play = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
      setIsPlaying(true);
    }
    setShow(false);
  };

  const pause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
      setIsPlaying(false);
    }
    setShow(true);
  };

  useEffect(() => {
    play();
  }, []);
  return (
    <div className={Classes.heroSection}>
      <Container>
        <Slider ref={sliderRef} {...settings}>
          <div className={Classes.sliderbg1}>
            <Row
              gutter={[20, 20]}
              className="d-flex justify-content-center align-items-center"
            >
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>Support good causes and make like-minded friends</h3>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-lg-end justify-content-center">
                  <img
                    className={Classes.sliderImg}
                    src={Slide1img}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
            {!auth.email && (
              <Link to="/sign-up" className={Classes.whiteBtn}>
                Join Us
              </Link>
            )}
          </div>
          <div className={`${Classes.sliderbg1}`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>Support your favorite charities with NFTs</h3>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <Row gutter={[20, 20]}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={Classes.txtSection}>
                      <div className={Classes.circle}>1</div>
                      <h3>Explore or create NFTs</h3>
                      <p>
                        Discover NFTs dedicated to charitable causes across
                        various themes and missions
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={Classes.txtSection}>
                      <div className={Classes.circle}>2</div>
                      <h3>Buy, sell, and donate NFTs</h3>
                      <p>
                        Easily contribute by selecting NFTs from your wallet,
                        choosing a cause, and confirming your donation
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={Classes.txtSection}>
                      <div className={Classes.circle}>3</div>
                      <h3>Track your Impact</h3>
                      <p>
                        Stay informed about the progress and results of your
                        charitable contributions
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div className={Classes.txtSection}>
                      <div className={Classes.circle}>4</div>
                      <h3>Find friends with shared interests</h3>
                      <p>
                        Create community and make friends with common interest
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {!auth.email && (
              <Link to="/sign-up" className={Classes.whiteBtn}>
                Join Us
              </Link>
            )}
          </div>
          <div className={`${Classes.sliderbg1}`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>
                    Woodies NFT by Trees for the Future were sold out in 10
                    minutes after launch
                  </h3>
                  <p className={Classes.date}>(Sept. 19, 2021)</p>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-lg-end justify-content-center">
                  <img
                    className={Classes.sliderImg}
                    src={Slide3img}
                    alt="img"
                  />
                </div>
              </Col>
              {!auth.email && (
                <Link to="/sign-up" className={Classes.whiteBtn}>
                  Join Us
                </Link>
              )}
            </Row>
          </div>
          <div className={`${Classes.sliderbg1}`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>
                    Rolls Royce’s Phantom automobile NFTs raised $1 million for
                    charity
                  </h3>
                  <p className={Classes.date}>(Nov 9, 2022)</p>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-lg-end justify-content-center">
                  <img
                    className={Classes.sliderImg}
                    src={Slide4img}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
            {!auth.email && (
              <Link to="/sign-up" className={Classes.whiteBtn}>
                Join Us
              </Link>
            )}
          </div>
          <div className={`${Classes.sliderbg1}`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>
                    Fidelity Charitable $1,000 NFTs supported buyers' favorite
                    charities
                  </h3>
                  <p className={Classes.date}>(Nov 16, 2022)</p>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-lg-end justify-content-center">
                  <img
                    className={Classes.sliderImg}
                    src={Slide5img}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
            {!auth.email && (
              <Link to="/sign-up" className={Classes.whiteBtn}>
                Join Us
              </Link>
            )}
          </div>
          <div className={`${Classes.sliderbg1}`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className={Classes.txtBlock}>
                  <h3>
                    50 artists and crypto.com NFTs offered earthquake relief in
                    Turkey
                  </h3>
                  <p className={Classes.date}>(Feb 21, 2023)</p>
                </div>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-lg-end justify-content-center">
                  <img
                    className={Classes.sliderImg}
                    src={Slide6img}
                    alt="img"
                  />
                </div>
              </Col>
            </Row>
            {!auth.email && (
              <Link to="/sign-up" className={Classes.whiteBtn}>
                Join Us
              </Link>
            )}
          </div>
        </Slider>
        <div className={Classes.actionbtn}>
          {show ? (
            <Tooltip placement="bottom" title="Play Slider">
              <img src={PauseBtn} alt="Pause" onClick={play} />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Pause Slider">
              <img src={PlayBtn} alt="Play" onClick={pause} />
            </Tooltip>
          )}
        </div>
      </Container>
    </div>
  );
}

export default HeroSection;
