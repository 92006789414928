import React, { useState, useEffect, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./history.module.scss";
import check from "../../assets/images/icons/verified.svg";
import { Tooltip, Modal } from "antd";
import { Container } from "react-bootstrap";
import icon1 from "../../assets/images/icons/socialIcon1.svg";
import icon2 from "../../assets/images/icons/socialIcon2.svg";
import icon3 from "../../assets/images/icons/socialIcon3.svg";
import icon4 from "../../assets/images/icons/socialIcon4.svg";
import icon5 from "../../assets/images/icons/twitter-new.svg";
import icon6 from "../../assets/images/icons/socialIcon6.svg";
import icon7 from "../../assets/images/icons/socialIcon7.svg";
import icon8 from "../../assets/images/icons/socialIcon8.svg";
import icon9 from "../../assets/images/icons/socialIcon9.svg";
import share from "../../assets/images/icons/share.svg";
import img1 from "../../assets/images/section/user.svg";
import { useSelector } from "react-redux";
import Banner from "../../assets/images/section/defaultBG.png";
import ReportNonProfitModal from "./ReportNonProfitModal";
import { BsFillFlagFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { use } from "react";
import { addNewFriend,respondFriendRequest } from "../../services/friends.service";
import { alertMessage } from "../../constants/alertMessage";
import CommonModal from "../views/Pages/CommonModal";
import { ThreeDots } from "react-loader-spinner";

const PublicUserTabs = React.lazy(() => import("../UserTabs/PublicUserTabs"));

const PublicUserHistory = ({ organization,handleReportNonProfit }) => {
  const socialMedia =
    organization?.socialMedia?.length && organization?.socialMedia[0];
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFriendModalOpen, setIsFriendModalOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const [recievedFriendRequest, setRecievedFriendRequest] = useState(null);
  const [requestData, setRequestData] = useState({});

  const [sentFriendRequest, setSentFriendRequest] = useState(null);

  const [isRejectloading, setIsRejectLoading] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const auth = useSelector((state) => state?.auth);
const authenticationToken = localStorage.getItem("authenticationToken");
  
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showFriendModal = () => {
    setIsFriendModalOpen(true);
  };

  const handleFriendCancel = () => {
    setIsFriendModalOpen(false);
  };
  const showRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const handleRejectCancel = () => {
    setIsRejectModalOpen(false);
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const copyToClipboard = () => {
    const profileUrl = window.location.href;

    navigator.clipboard.writeText(profileUrl).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };

  useEffect(() => {
    if (organization?.success === false) {
      if (organization?.status === 403) {
        navigate("/hidden-profile");
      } else {
        navigate("/not-found");
      }
    }
  });
  // Function to find a friend request by userId and friendId
  const findFriendRequest = (organization, authId, friendId) => {
    if (!organization?.receivedFriendRequests) return null;

    return organization.receivedFriendRequests.find(request => 
      request.userId === authId && request.friendId === friendId
    );
  };
  const findSentFriendRequest = (organization, authId, friendId) => {
    if (!organization?.sentFriendRequests) return null;

    return organization.sentFriendRequests.find(request => 
      request.userId === friendId && request.friendId === authId
    );
  };

  const addFriend = async (friendId) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      const data = { friendId, userId: auth?.id, requestStatus: "pending" };
      const friend = await addNewFriend(data);
      if (friend?.data) { 
        handleReportNonProfit();
        showFriendModal();
        setMessage(friend?.data?.message);
        setModalType("Request Sent");
        setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
      }
    } catch (error) {
      console.log(error);
      alertMessage("error", `${error}`);
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
    }
  };

  const handleFriendRequest = async (friendId, requestStatus, id) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      const data = {
        userId: friendId,
        friendId: auth?.id,
        requestStatus,
      }

      if (requestStatus === "reject") {
        setIsRejectLoading(true);
      }
      if(requestStatus === "removed"){
        setIsRejectLoading(true);
      }
      const requests = await respondFriendRequest(data);

      if (requests?.data) {
        setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
        if (requestStatus === "reject") {
          setIsRejectLoading(false);
         handleRejectCancel();
        }
        if(requestStatus === "removed"){
          setIsRejectLoading(false);
          handleCancel();
        }
        if(requestStatus !== "removed"){
        requestStatus === "reject" 
          ? alertMessage("success", "Friend request rejected successfully.")
          : alertMessage("success", "Friend request accepted successfully.");
        }
        if(requestStatus === "removed"){alertMessage("success", "Friend removed successfully.");}
        handleReportNonProfit();
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      setIsRejectLoading(false);
      console.log("error.response", error.response.message);
    }
  };
  const rejection = async () => {
    await handleFriendRequest(
      requestData?.friendId,
      requestData?.requestStatus
    );
  };
  useEffect(() => {
    const friendRequest = findFriendRequest(organization, auth?.id, organization?._id);
    setRecievedFriendRequest(friendRequest);
    const sentFriendRequest = findSentFriendRequest(organization, auth?.id, organization?._id);
    setSentFriendRequest(sentFriendRequest);
  }, [organization]);

  return (
    <div className={`${Classes.history}`}>
      <Helmet>
        <title>{organization?.nonProfitName || organization?.username}</title>
        <meta
          name="title"
          content={organization?.nonProfitName || organization?.username}
        />
        <meta
          name="description"
          content={organization?.missionStatement || organization?.bio}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/user/${organization?.username}`}
        />
      </Helmet>
      <div
        className={Classes.myBannerImg}
        style={{
          backgroundImage: `url(${organization?.bannerImage || Banner})`,
        }}
      ></div>

      <div className={`${Classes.userDetails}`}>
        <Container>
          <>
            <div className={`${Classes.userInfo}`}>
              <div className="d-flex align-items-md-center gap-md-3 gap-1 flex-sm-row flex-column">
                <div
                  className={
                    organization?.role === "user"
                      ? Classes.userImg
                      : Classes.orgImg
                  }
                >
                  <img
                    src={organization?.profilePicture || img1}
                    alt="Loading..."
                  />
                </div>
                <div>
                  <div className="pt-sm-5 pt-0 w-100">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <h3 className={Classes.userName}>
                        {organization?.nonProfitName || organization?.username
                          ? `${organization?.nonProfitName || organization?.username}`
                          : "Loading..."}
                      </h3>
                      {organization &&
                        organization?.role !== "user" &&
                        organization?.verificationStatus === "approved" && (
                          <img
                            className={Classes.varified}
                            src={check}
                            alt="icon"
                          />
                        )}
                    </div>
                    {organization && organization?.role !== "user" && (
                      <div className={Classes.metaInfo}>
                        {organization?.organizationType &&
                        organization?.organizationType.includes(
                          "fiscal sponsor"
                        ) &&
                        (!organization?.verificationStatus ||
                          organization?.verificationStatus === "disapproved")
                          ? "Pending approval by fiscal sponsor"
                          : organization?.organizationType
                            ? organization?.organizationType ===
                              "Nonprofit seeking a fiscal sponsor or 501(c)(3) status"
                              ? "Nonprofit with 501(c)(3) fiscal sponsor"
                              : organization?.organizationType
                            : ""}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${Classes.socialIcons} d-flex align-items-center pt-2 flex-sm-row flex-column`}
                  >
                    <div
                      className={`${Classes.outer} d-flex align-items-center justify-content-sm-start justify-content-between gap-4 py-2 pe-sm-4 pe-0 me-sm-4 me-0`}
                    >
                      {socialMedia?.facebook && (
                        <Tooltip title="Facebook">
                          <a
                            href={socialMedia?.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon7} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.youtube && (
                        <Tooltip title="YouTube">
                          <a
                            href={socialMedia?.youtube}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon8} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.linkedIn && (
                        <Tooltip title="LinkedIn">
                          <a
                            href={socialMedia?.linkedIn}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon9} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.discord && (
                        <Tooltip title="Discord">
                          <a
                            href={socialMedia?.discord}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon3} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.tiktok && (
                        <Tooltip title="TikTok">
                          <a
                            href={socialMedia?.tiktok}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon6} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.website && (
                        <Tooltip title="Website">
                          <a
                            href={socialMedia?.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon2} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.instagram && (
                        <Tooltip title="Instagram">
                          <a
                            href={socialMedia?.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon4} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.twitter && (
                        <Tooltip title="Twitter">
                          <a
                            href={socialMedia?.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon5} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {auth?.email && (
                        <Tooltip title="Report">
                          <div
                            className="cursor_pointer"
                            onClick={() => showModal()}
                          >
                            <BsFillFlagFill
                              color="#000"
                              className={Classes.flagIcon}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <button
                      className={Classes.linkBtn}
                      onClick={copyToClipboard}
                    >
                      <div className={`d-flex align-items-center gap-2`}>
                        <FaLink />
                        <p>
                          {tooltipVisible ? "Copied!" : "Copy profile link"}
                        </p>
                      </div>
                    </button>
                    {authenticationToken && auth?.role === "user" && organization?.role === "user" &&<div>
                    { recievedFriendRequest?.requestStatus !== "reject" && !sentFriendRequest &&
                    <button
                      className={Classes.linkBtn} style={{marginLeft:"10px"}}
                    >
                      <div className={`d-flex align-items-center gap-2`}>
                        {recievedFriendRequest?.requestStatus === "pending" && <p>Wait for friend</p>}
                        {recievedFriendRequest?.requestStatus === "approved" && <p onClick={() =>
                          handleFriendRequest(
                            recievedFriendRequest?.friendId,
                            "removed"
                          )
                        }>Remove friend</p>}
                        {!recievedFriendRequest?.requestStatus && !sentFriendRequest?.requestStatus && <p onClick={() => addFriend(organization?._id)}  >{loading[organization?._id] ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#d9d9d9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Add Friend"
                      )}</p>}
                      </div>
                    </button>}
                    {sentFriendRequest && sentFriendRequest?.requestStatus !== "reject" &&<button
                      className={Classes.linkBtn} style={{marginLeft:"10px"}}
                    >
                      <div className={`d-flex align-items-center gap-2`}>
                        {sentFriendRequest?.requestStatus === "pending" && <p onClick={() =>
                          handleFriendRequest(
                            sentFriendRequest?.userId,
                            "approved"
                          )
                        }>{loading[sentFriendRequest?.userId] ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Accept friend"
                        )}</p>}
                        {sentFriendRequest?.requestStatus === "approved" && <p onClick={() =>
                          handleFriendRequest(
                            sentFriendRequest?.userId,
                            "removed"
                          )
                        }>Remove friend</p>}
                      </div>
                    </button>}
                    {sentFriendRequest?.requestStatus === "pending" &&<button
                      className={Classes.rejectBtn} style={{marginLeft:"10px"}}
                      onClick={() => {
                        showRejectModal()
                        setRequestData({
                          friendId: sentFriendRequest?.userId,
                          requestStatus: "reject",
                        });
                      }}
                    >
                      <div className={`d-flex align-items-center gap-2`}>
                        {sentFriendRequest?.requestStatus === "pending" && <p>Reject friend</p>}
                      </div>
                    </button>}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </>
          <Suspense
            fallback={
              <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            }
          >
            <PublicUserTabs organization={organization} />
          </Suspense>
        </Container>

        <ReportNonProfitModal
          organization={organization}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
         <CommonModal
        showModal={showFriendModal}
        isModalOpen={isFriendModalOpen}
        handleCancel={handleFriendCancel}
        message={message}
        modalType={modalType}
      />
      <Modal open={isRejectModalOpen} footer={null} onCancel={handleRejectCancel}>
        <div
          className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
        >
          <h1>Reject!</h1>
          <p className="mb-3">
            Are you sure you want to reject this person’s friend request?
          </p>
          <div className="d-flex gap-3">
            <button
              className={`${Classes.btn} ${Classes.accept}`}
              onClick={handleRejectCancel}
            >
              No
            </button>
            <button
              className={`${Classes.btn} ${Classes.reject}`}
              onClick={() => rejection()}
            >
              {isRejectloading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="red"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
      </div>
    </div>
  );
};

export default PublicUserHistory;
