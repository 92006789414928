import React, { useEffect, useState } from "react";
import Classes from "./leaderboard.module.scss";
import { Container } from "react-bootstrap";
import { Tabs, Row, Col } from "antd";
import LeaderBoardPublic from "../LeaderBoardPublic";
import { leaderBoardList } from "../../services/auth.service";
import { Link } from "react-router-dom";
import { Pagination } from 'antd';


const LeaderBoard = () => {
  const authToken = localStorage.getItem("authenticationToken");     
  const [selectedOption, setSelectedOption] = useState("organization");      
  const [leaderBoardData, setLeaderBoardData] = useState(null);               
  const currentUrl = window.location.href;
  const [limit,setLimit] = useState(12);
  const [page, setPage] = useState(1);
  
 
  const handleChange = (value) => {
    setSelectedOption(value);         
  };
  const getLeaderBoardData = async () => {    
    const response = await leaderBoardList(authToken, selectedOption, limit,page);        
    if (response?.data?.success) {         
      setLeaderBoardData(response?.data?.data[0]);                     
    }      
  };
  useEffect(() => {
    setPage(1);
    setLimit(12);
    getLeaderBoardData();
  }, [selectedOption]);

  useEffect(() => {
    getLeaderBoardData();
  }, [page, limit]);

  const handlePageClick = (page,pageSize) => {
    setPage(page); 
    setLimit(pageSize);
  };

  return (
    <div className={Classes.leaderBoard}>           
      <Container className="position-relative">                      
        <div className="d-flex align-items-center gap-3 mb-4">                                    
          <h1 className={Classes.title}>Leaderboards</h1>                                            
          {!currentUrl.includes("leaderboards") && (                                                       
            <Link to="/leaderboards" className={Classes.seeAllBtn}>                                      
              See More        
            </Link>   
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2">                     
          <Tabs defaultActiveKey="organization" onChange={handleChange}>                       
            <Tabs.TabPane tab="Nonprofits" key="organization">                    
              <div className="mt-4">   
                <Row gutter={[20, 20]}>                       
                  {leaderBoardData?.data &&                    
                    leaderBoardData?.data.map((index, user) => (                          
                      <Col lg={8} md={12} sm={24} xs={24} key={index}>                                
                        <Link to={`/user/${index?.username}`}>            
                          <LeaderBoardPublic index={index} user={user} />            
                        </Link>
                      </Col>
                    ))}
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Donors" key="user">
              <div className="mt-4">
                <Row gutter={[20, 20]}>
                  {leaderBoardData?.data &&
                    leaderBoardData?.data.map((index, user) => (     
                      <Col lg={8} md={12} sm={24} xs={24} key={index}>       
                        <Link to={`/user/${index?.username}`}>         
                          <LeaderBoardPublic index={index} user={user} />                      
                        </Link>          
                      </Col>
                    ))}
                </Row>           
              </div>
            </Tabs.TabPane>
            
          </Tabs>     
          <div
            className={`${Classes.actionBtn} d-flex align-items-center gap-3`}
          >
          </div>
         
        </div>
        {currentUrl.includes("leaderboards") && (
              <Pagination
                current={page} // Current page
                pageSize={limit} // Items per page
                total={leaderBoardData?.metadata?.total} // Total items
                onChange={handlePageClick} // Handle page change
                showSizeChanger
        pageSizeOptions={['24', '48', '72', '96']} // Updated options
              onShowSizeChange={handlePageClick}
              style={{ textAlign: 'center', margin: '20px 0' }} // Center the pagination
            />
            )}
      </Container>
    </div>
  );
};

export default LeaderBoard;
