import React, { Fragment, useState, useEffect } from "react";
import Classes from "./profile.module.scss";
import Edit from "../../assets/images/icons/edit.svg";
import banner from "../../assets/images/section/profileBanner.png";
import * as Yup from "yup";
import { Formik } from "formik";
import Select2 from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { alertMessage } from "../../constants/alertMessage";
import { updateProfile } from "../../services/auth.service";
import { thunkLogin } from "../../store/_actions/user_action";
import UserImg from "../../assets/images/section/darkBlueuser.svg";
import img1 from "../../assets/images/section/user.svg";
import img2 from "../../assets/images/section/greenuser.svg";
import img3 from "../../assets/images/section/pinkuser.svg";
import img4 from "../../assets/images/section/darkBlueuser.svg";
import img5 from "../../assets/images/section/purpleuser.svg";
import img6 from "../../assets/images/section/yellowuser.svg";
import img7 from "../../assets/images/section/reduser.svg";
import img8 from "../../assets/images/section/peachuser.svg";
import img9 from "../../assets/images/section/darkPurpleuser.svg";
import img10 from "../../assets/images/section/orangeuser.svg";
import img11 from "../../assets/images/section/skyblueuser.svg";
import img12 from "../../assets/images/section/parrotuser.svg";
import countriesData from '../../helpers/countries.json';
import statesData from '../../helpers/states.json';
import citiesData from '../../helpers/cities.json';
import axios from "axios";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { validateUsername } from "../../services/auth.service";

const Profile = ({ setProfilePicture, setFormFieldsData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileImg, setProfileImg] = useState(img4);
  const imageUrls = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];
  const auth = useSelector((state) => state.auth);
  const [bannerFile, setBannerFile] = useState(auth?.bannerImage);
  const [textLimit, setTextLimit] = useState(auth?.bio?.length || 500);
  const [profilepPictureFile, setProfilepPictureFile] = useState(
    auth?.profilePicture
  );

  const [bannerFilePreview, setBannerFilePreview] = useState(auth?.bannerImage);
  const [profilepPictureFilePreview, setProfilepPictureFilePreview] = useState(
    auth?.profilePicture
  );
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [initialSelectedCountry, setInitialSelectedCountry] = useState(null);
  const [initialSelectedState, setInitialSelectedState] = useState(null);
  const [initialSelectedCity, setInitialSelectedCity] = useState(null);
  const [isUsernameUnique, setIsUsernameUnique] = useState("");


  const dispatch = useDispatch();

  const handleBannerUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setBannerFilePreview(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfilePictureUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilepPictureFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setProfilepPictureFilePreview(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageClick = (imageUrl) => {
    setProfilepPictureFilePreview(imageUrl);
    setProfilepPictureFile(`${process.env.REACT_APP_BASE_URL}${imageUrl}`);
  };

  const handleUsernameExist = async (username) => {
    try {
      const trimmedUsername = username.trim();
      if(trimmedUsername.length>3){
        const authToken = localStorage.getItem("authenticationToken");
        const response = await validateUsername(authToken, trimmedUsername);
  
        if (response?.status === 200) {
          setIsUsernameUnique("available");
        } else {
          setIsUsernameUnique("taken");
        }
      }else{
        setIsUsernameUnique("");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };
   
  const handleProfileUpdate = async (data) => {
    try {
      setIsLoading(true);
      const authToken = localStorage.getItem("authenticationToken");
      let requestData = {
        ...data,
        authToken,
      };

      if (bannerFile) {
        requestData = {
          ...data,
          authToken,
          bannerFile,
        };
      }
      if (profilepPictureFile) {
        requestData = {
          ...data,
          authToken,
          bannerFile,
          profilepPictureFile,
        };
      }
      const response = await updateProfile(requestData);
      if (response?.data?.success === true) {
        dispatch(
          thunkLogin(response?.data?.data, () => {
            alertMessage("success", `${response?.data?.message}`);
            setIsLoading(false);
          })
        );
      } else {
        alertMessage("error", `${response?.message}.`);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alertMessage("error", error);
    }
  };

  const handleCharacterLimit = (event) => {
    const newText = event.target.value;
    const remainingChars = 500 - newText.length;

    if (newText.length <= 500) {
      setTextLimit(remainingChars);
    }
  };

  useEffect(() => {
    // Load countries from the JSON file
    setCountries(countriesData.map(country => ({ value: country.id, label: country.name })));
  }, []);
  useEffect(() => {
    if (selectedCountry) {
      // Filter states based on selected country
      const filteredStates = statesData
        .filter(state => state.country_id === selectedCountry.value)
        .map(state => ({ value: state.id, label: state.name }));
      setStates(filteredStates);
      setCities([]); // Reset cities when country changes
      setSelectedState(null); // Reset selected state
      setInitialSelectedState(null);
      setSelectedCity(null);
      setInitialSelectedCity(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Filter cities based on selected state
      const filteredCities = citiesData
        .filter(city => city.state_id === selectedState.value)
        .map(city => ({ value: city.id, label: city.name }));
      setCities(filteredCities);
      setSelectedCity(null); // Reset selected city
      setInitialSelectedCity(null);
    }
  }, [selectedState]);
  useEffect(() => {
    const countryOptions = countriesData.map(country => ({ value: country.id, label: country.name }));
    setCountries(countryOptions);

    // Set initial selected country
    const initialCountry = countryOptions.find(country => country.label === auth.country);
    setInitialSelectedCountry(initialCountry);

    if (initialCountry) {
      const filteredStates = statesData
        .filter(state => state.country_id === initialCountry.value)
        .map(state => ({ value: state.id, label: state.name }));
      setStates(filteredStates);

      // Set initial selected state
      const initialState = filteredStates.find(state => state.label === auth.state);
      setInitialSelectedState(initialState);

      if (initialState) {
        const filteredCities = citiesData
          .filter(city => city.state_id === initialState.value)
          .map(city => ({ value: city.id, label: city.name }));
        setCities(filteredCities);

        // Set initial selected city
        const initialCity = filteredCities.find(city => city.label === auth.city);
        setInitialSelectedCity(initialCity);
      }
    }
  }, [auth]);
  return (
    <div className={Classes.profile}>
      <h1 className={Classes.title}>Profile Details</h1>
      <div className="mb-4">
        <p className={Classes.label}>Banner image</p>

        <div className={Classes.bannerImg}>
          <img src={bannerFilePreview || banner} alt="img" />
          <div className={Classes.editIcon}>
            <input type="file" />
            <input type="file" accept="image/*" onChange={handleBannerUpload} />
            <img src={Edit} alt="icon" />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className={Classes.label}>Profile image</p>
        <div className={Classes.profileImg}>
          <img src={profilepPictureFilePreview || UserImg} alt="img" />
          <div className={`${Classes.editIcon} ${Classes.edit2}`}>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfilePictureUpload}
            />
            <img src={Edit} alt="icon" />
          </div>
        </div>
        {auth?.role==='user' &&<div className={Classes.avatar}>
          {imageUrls.map((imageUrl, i) => (
            <div
              key={i}
              className="grid-item"
              onClick={() => handleImageClick(imageUrl)}
            >
              <img
                src={imageUrl}
                alt={`Img ${i}`}
                className={Classes.shortImg}
              />
            </div>
          ))}
        </div>}
      </div>
      <Formik
        initialValues={{
          nonProfitName: auth?.nonProfitName ? auth?.nonProfitName : "",
          firstName: auth?.firstName ? auth?.firstName : "",
          lastName: auth?.lastName ? auth?.lastName : "",
          username: auth?.username ? auth?.username : "",
          email: auth?.email ? auth?.email : "",
          missionStatement: auth?.missionStatement ? auth?.missionStatement : "",
          bio: auth?.bio ? auth?.bio : "",
          address: auth?.address ? auth?.address : "",
          addressTwo: auth?.addressTwo ? auth?.addressTwo : "",
          city: selectedCity ? selectedCity?.label : "",
          state: selectedState ? selectedState?.label : "",
          zipCode: auth?.zipCode ? auth?.zipCode : "",
          country: selectedCountry ? selectedCountry?.label : "",
          defaultSponsorshipFee: auth?.defaultSponsorshipFee
            ? auth?.defaultSponsorshipFee
            : "",
        }}
        validationSchema={Yup.object().shape({
          nonProfitName: auth?.role === "organization" 
          ? Yup.string()
              .max(60, ({ max }) => `Nonprofit name should not exceed ${max} characters.`)
              .min(1, ({ min }) => `Nonprofit name should have at least ${min} characters.`)
              .required("Nonprofit name is required.")
          : Yup.string()
              .max(60, ({ max }) => `Nonprofit name should not exceed ${max} characters.`)
              .optional(),
          firstName: Yup.string()
            .max(
              20,
              ({ max }) => `First name should not exceed ${max} characters.`
            )
            .min(
              1,
              ({ min }) => `First name should have at least ${min} characters.`
            )

            .required("First name is required."),
          lastName: Yup.string()
            .max(
              20,
              ({ max }) => `Last name should not exceed ${max} characters.`
            )
            .min(
              1,
              ({ min }) => `Last name should have at least ${min} characters.`
            )

            .required("Last name is required."),
          username: Yup.string()
            .max(
              20,
              ({ max }) => `Username should not exceed ${max} characters.`
            )
            .required("Username is required."),
            missionStatement: auth?.role === "organization"
            ? Yup.string()
                .trim()
                .min(50, ({ min }) => `Mission should have at least ${min} characters.`)
                .max(600, "Maximum 600 characters allowed")
                .required("Mission statement is required.")
            : Yup.string()
                .trim()
                .min(50, ({ min }) => `Mission should have at least ${min} characters.`)
                .max(600, "Maximum 600 characters allowed")
                .optional(),
            bio:Yup.string()
                .trim()
                .min(50, ({ min }) => `Bio should have at least ${min} characters.`)
                .max(600, "Maximum 600 characters allowed")
                .optional(),  
          address: Yup.string()
            .max(
              100,
              ({ max }) => `Address should not exceed ${max} characters.`
            )
            .required("Address is required."),
          addressTwo: Yup.string()
            .max(
              100,
              ({ max }) => `Address Line 2 should not exceed ${max} characters.`
            )
            .optional(),
          city: Yup.string()
            // .max(20, ({ max }) => `City should not exceed ${max} characters.`)
            // .min(2, ({ min }) => `City should have at least ${min} characters.`)
            .required("City is required."),
          state: Yup.string()
            // .max(20, ({ max }) => `State should not exceed ${max} characters.`)
            // .min(2, ({ min }) => `State should have at least ${min} characters.`)
            .required("State is required."),
          zipCode: Yup.string()
            .max(10, ({ max }) => `Zip code should not exceed ${max} digits.`)
            .min(
              4,
              ({ min }) => `Zip code should have at least ${min} characters.`
            )
            .required("Zip code is required."),
          country: Yup.string()
            // .max(
            //   20,
            //   ({ max }) => `Country should not exceed ${max} characters.`
            // )
            // .min(
            //   2,
            //   ({ min }) => `Country should have at least ${min} characters.`
            // )
            .required("Country is required."),
          // defaultSponsorshipFee: Yup.number()
          //   .required("Country is required.")
          //   .min(1, "Sponsorship fee must be greater than or equal to 1.")
          //   .max(10, "Sponsorship fee must be less than or equal to 10."),
        })}
        onSubmit={(values, { resetForm }) => {
          const data = {
            nonProfitName: values?.nonProfitName,
            firstName: values?.firstName,
            lastName: values?.lastName,
            username: values?.username,
            email: values?.email,
            missionStatement: values?.missionStatement,
            bio: values?.bio,
            address: values?.address,
            addressTwo: values?.addressTwo,
            city: values?.city ,
            state: values?.state ,
            zipCode: values?.zipCode,
            country:values?.country,
          };
          handleProfileUpdate(data, resetForm);
        }}
        textAlign="center"
      >
        {({
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
            noValidate
            autoComplete="off"
          >
            <div className={Classes.formOuter}>
            {auth?.role ==='organization' && <div className="mb-3">
                <p className={Classes.label}>Nonprofit Name*</p>
                <input
                  className={Classes.input}
                  type="text"
                  name="nonProfitName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.nonProfitName}
                  placeholder="Enter nonprofit name"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.nonProfitName && touched.nonProfitName ? (
                    <Fragment>{errors.nonProfitName}</Fragment>
                  ) : null}
                </span>
              </div>}
              <div className="mb-3">
                <p className={Classes.label}>First Name*</p>
                <input
                  className={Classes.input}
                  type="text"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.firstName}
                  placeholder="Enter first name"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.firstName && touched.firstName ? (
                    <Fragment>{errors.firstName}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Last Name*</p>
                <input
                  className={Classes.input}
                  type="text"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.lastName}
                  placeholder="Enter last name"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.lastName && touched.lastName ? (
                    <Fragment>{errors.lastName}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                <p className={Classes.label}>Username*</p>
                <p
                      className={`${Classes.txt} d-flex align-items-center gap-1`}
                    >
                      {isUsernameUnique === "available" ? (
                        <>
                          <AiFillCheckCircle fontSize="20px" color="green" />
                          Username available
                        </>
                      ) : isUsernameUnique === "taken" ? (
                        <>
                          <AiFillCloseCircle fontSize="20px" color="red" />
                          Username already taken
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                
                </div>
                <input
                  className={Classes.input}
                  type="text"
                  name="username"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleUsernameExist(e.target.value);
                  }}
                  value={values?.username}
                  placeholder="Enter username"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.username && touched.username ? (
                    <Fragment>{errors.username}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Email Address*</p>
                <input
                  className={Classes.input}
                  disabled
                  type="email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.email}
                  placeholder="Enter email"
                />
              </div>
              {auth?.role ==='organization' && <div className="mb-3">
                <p className={Classes.label}>Mission (50 to 600 characters)*</p>
                <textarea
                  className={Classes.input}
                  rows="6"
                  type="text"
                  name="missionStatement"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleCharacterLimit(e);
                  }}
                  value={values.missionStatement}
                  placeholder="Tell the world your story!"
                />

                {/* <div className={Classes.charCount}>{`0 / ${textLimit}`}</div> */}
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.missionStatement && touched.missionStatement ? (
                    <Fragment>{errors.missionStatement}</Fragment>
                  ) : null}
                </span>
              </div>}
              {auth?.role ==='user' && <div className="mb-3">
                <p className={Classes.label}>Bio (50 to 600 characters)</p>
                <textarea
                  className={Classes.input}
                  rows="6"
                  type="text"
                  name="bio"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleCharacterLimit(e);
                  }}
                  value={values.bio}
                  placeholder="Tell the world your story!"
                />

                {/* <div className={Classes.charCount}>{`0 / ${textLimit}`}</div> */}
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.bio && touched.bio ? (
                    <Fragment>{errors.bio}</Fragment>
                  ) : null}
                </span>
              </div>}

              <div className="mb-3">
                <p className={Classes.label}>Address*</p>
                <textarea
                  className={Classes.input}
                  type="text"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.address}
                  placeholder="Enter address"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.address && touched.address ? (
                    <Fragment>{errors.address}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Address Line 2</p>
                <textarea
                  className={Classes.input}
                  type="text"
                  name="addressTwo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.addressTwo}
                  placeholder="Enter address line 2"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.addressTwo && touched.addressTwo ? (
                    <Fragment>{errors.addressTwo}</Fragment>
                  ) : null}
                </span>
              </div>
              
              
              <div className="mb-3">
                <p className={Classes.label}>Country*</p>
                <Select2
                    className={Classes.selectInput}
                    value={selectedCountry || initialSelectedCountry}
                    onChange={(option) => {
                      setSelectedCountry(option);
                      setFieldValue("country", option ? option.label : "");
                      setSelectedState(null);
                      setInitialSelectedState(null);
                      setFieldValue("state", "");
                      setSelectedCity(null);
                      setInitialSelectedCity(null);
                      setFieldValue("city", "");
                    }}
                    options={countries}
                    placeholder="Select Country"
                  />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.country && touched.country ? (
                    <Fragment>{errors.country}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>State*</p>
                <Select2
                    className={Classes.selectInput}
                    value={selectedState || initialSelectedState}
                    onChange={(option) => {
                      setSelectedState(option);
                      setSelectedCity(null);
                      setInitialSelectedCity(null);
                      setFieldValue("city", "");
                      setFieldValue("state", option ? option.label : "");
                    }}
                    options={states}
                    placeholder="Select State"
                    isDisabled={!selectedCountry}
                  />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.state && touched.state ? (
                    <Fragment>{errors.state}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>City*</p>
                <Select2
                    className={Classes.selectInput}
                    value={selectedCity || initialSelectedCity}
                    onChange={(option) => {
                      setSelectedCity(option);
                      setFieldValue("city", option ? option.label : "");
                    }}
                    options={cities}
                    placeholder="Select City"
                    isDisabled={!selectedState}
                  />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.city && touched.city ? (
                    <Fragment>{errors.city}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Zip Code*</p>
                <input
                  className={Classes.input}
                  type="number"
                  name="zipCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.zipCode}
                  placeholder="Enter zip code"
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.zipCode && touched.zipCode ? (
                    <Fragment>{errors.zipCode}</Fragment>
                  ) : null}
                </span>
              </div>
              {/* <div className='mb-3'>
                <p className={Classes.label}>Fiscal Project Sponsorship Fee </p>
                <input
                  className={Classes.input}
                  type='number'
                  name='defaultSponsorshipFee'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.defaultSponsorshipFee}
                  placeholder='Enter fiscal project sponsorship fee'
                />
                <span
                  className='mt-1 d-block'
                  size={'small'}
                  style={{ color: 'red' }}
                >
                  {errors.defaultSponsorshipFee &&
                  touched.defaultSponsorshipFee ? (
                    <Fragment>{errors.defaultSponsorshipFee}</Fragment>
                  ) : null}
                </span>
              </div> */}
              {/* <div className='mb-3'>
                <p className={Classes.label}>Social Connections</p>
                <p className={Classes.txt}>
                  Help collectors verify your account by connecting social media
                  accounts
                </p>
              </div>
              <div className='mb-3 d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-2'>
                  <img src={Twitter} alt='icon' />
                  <p className={`${Classes.label} mb-0`}>Twitter</p>
                </div>
                <button className={Classes.blueBtn}>Connect</button>
              </div>
              <div className='mb-3 d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-2'>
                  <img src={Insta} alt='icon' />
                  <p className={`${Classes.label} mb-0`}>Instagram</p>
                </div>
                <button className={Classes.blueBtn}>Connect</button>
              </div>
              <div className='mb-3'>
                <p className={Classes.label}>Links</p>
                <input className={Classes.input} placeholder='yoursite.io' />
              </div> */}

              {auth?.walletAddress ? (
                <div className="mb-3">
                  <p className={Classes.label}>Wallet Address*</p>
                  <input
                    className={Classes.input}
                    type="text"
                    disabled
                    value={auth?.walletAddress}
                    placeholder="Enter wallet address"
                  />
                </div>
              ) : (
                // <div className='mb-3'>
                //   <p className={Classes.label}>Wallet Address</p>
                //   <p>
                //     {auth?.walletAddress}</p>
                // </div>
                ""
              )}
              <div className="mt-5">
                <button className={`${Classes.blueBtn} w-100`} type="submit"
                disabled={isUsernameUnique === "taken" || isLoading}
                >
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
