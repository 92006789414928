import React, { useEffect, useState, Suspense } from "react";
import Classes from "../UserHistory/history.module.scss";
import check from "../../assets/images/icons/verified.svg";
import { Container } from "react-bootstrap";
import CollectionImg from "../../assets/images/svg/collectionimg.svg";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { collectionDetail } from "../../services/collections.service";
import { useParams } from "react-router-dom";
import moment from "moment";
import img1 from "../../assets/images/section/user.svg";
import { Helmet } from 'react-helmet';

const CollectionDetailTabs = React.lazy(() => import('../CollectionDetailTabs'));

const CollectionDetailHistory = () => {
  const [collection, setCollection] = useState(null);
  const [collectionNfts, setCollectionNfts] = useState(null);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const [params, setParams] = useState({
    itemFilter: null,
    sortByPrice: null,
    dateFilter: null,
    search: null,
  });
  const authToken = localStorage.getItem("authenticationToken");
  const { id } = useParams();
  // useEffect(() => {
  //   if (!auth.email) {
  //     navigate("/login");
  //   }
  // }, []);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getCollection();
    }, 700);
    return () => clearTimeout(debounceTimeout);
  }, [params.search]);

  const getCollection = async () => {
    try {
        const response = await collectionDetail(id, params);
        if (response?.data?.data) {
          setCollection(response?.data?.data?.collection);
          setCollectionNfts(response?.data?.data?.nfts);
        }
    } catch (error) {
      console.log(error);
    }
  };
  const minFloorPrice = collection?.floorPrice?.reduce((min, current) => {
    return min < current.floorPrice ? min : current.floorPrice;
  }, 9999999999999);

  const maxTotalVolume =
    collection?.totalVolume.length &&
    collection?.totalVolume.reduce((max, { totalVolume }) => {
      return totalVolume > max ? totalVolume : max;
    }, 0);
  useEffect(() => {
    getCollection();
  }, [params]);
  const renderText = () =>
    showFullDescription ? (
      <p className={Classes.desc}>
        {collection?.description || ""}
        {
          <button className={Classes.showBtn} onClick={toggleDescription}>
            Show less
          </button>
        }
      </p>
    ) : (
      <p className={Classes.desc}>
        {collection?.description?.substring(0, 485)}
        {collection?.description.length > 485 && (
          <>
            ....
            <button className={Classes.showBtn} onClick={toggleDescription}>
              Show more
            </button>
          </>
        )}
      </p>
    );
  return (
    <div>
      <Helmet>
        <title>{collection?.collectionName}</title>
        <meta name="description" content={collection?.description} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": collection?.collectionName,
            "description": collection?.description,
            "url": window.location.href,
          })}
        </script>
      </Helmet>
      <div className={`${Classes.history}`}>
        <div className={`${Classes.bannerImg} ${Classes.bannerImg3}`}>
          <Container>
            <div className={`${Classes.innerContent} w-100`}>
              {/* <img src={collection?.collectionImage || CollectionImg} alt='img' /> */}
              <div>
                <img
                  className={Classes.img}
                  src={collection?.collectionImage || img1}
                  alt="Loading..."
                />
                <h1 className={Classes.coll_name}>
                  {collection?.collectionName}
                </h1>
                <h3 className={Classes.owner_name}>
                  {collection?.userId?.role === "user"
                      ? "Owned by user: "
                      : "Owned by nonprofit: "}
                      <span onClick={() => {navigate(`/user/${collection?.userId?.username}`);}} style={{cursor:'pointer'}}>
                        {collection?.userId?.role === "user"
                        ? collection?.userId?.username
                        : collection?.userId?.nonProfitName}
                      </span>
                </h3>
              </div>
              {collection && (
                <div
                  className={`${Classes.info} d-flex align-items-center gap-md-5 gap-4`}
                >
                  <div className="d-flex flex-column">
                    <b>{maxTotalVolume || 0}</b>
                    <span>Total volume</span>
                  </div>
                  <div className="d-flex flex-column">
                    <b>{minFloorPrice === 9999999999999 ? "0" : minFloorPrice}</b>
                    <span>Floor price</span>
                  </div>
                  <div className="d-flex flex-column">
                    <b>{collection?.bestOffer}</b>
                    <span>Best offer</span>
                  </div>
                  <div className="d-flex flex-column">
                    <b>{collection?.listedNfts}</b>
                    <span>Listed</span>
                  </div>
                  {/* <div className="d-flex flex-column">
                  <b>--</b>
                  <span>Owners</span>
                </div> */}
                </div>
              )}
            </div>
          </Container>
        </div>

        <div className={`${Classes.userDetails}`}>
          <Container>
            <>
              <div className="pt-5">
                <ul
                  className={`${Classes.items} d-flex align-items-md-center align-items-start gap-lg-5 gap-4 p-0 flex-md-row flex-column`}
                >
                  <li>
                    <span className={Classes.item}>Items</span>
                    <b className={Classes.value}>{collection?.count || 0}</b>
                  </li>
                  <li>
                    <span className={Classes.item}>Created</span>
                    <b className={Classes.value}>
                      {moment(collection?.createdAt)?.format("DD MMM YYYY") || ""}
                    </b>
                  </li>
                  {/* <li>
                    <span className={Classes.item}>Creator earnings </span>
                    <b className={Classes.value}>3.33%</b>
                  </li> */}
                  <li>
                    <span className={Classes.item}>
                      Charity Donations
                    </span>
                    <b className={Classes.value}>
                      {collection?.donation > 0
                        ? collection?.donation.toFixed(8)
                        : collection?.donation}{" "}
                      POL
                    </b>
                  </li>
                  <li>
                    <span className={Classes.item}>Chain </span>
                    <b className={Classes.value}>Polygon</b>
                  </li>
                </ul>
                {/* <p className={Classes.desc}>{collection?.description || ''}</p> */}
                <>{renderText()}</>
              </div>
            </>
            <Suspense
              fallback={
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              }
            >
              <CollectionDetailTabs
                nfts={collectionNfts}
                params={params}
                setParams={setParams}
                collectionDetails={collection}
              />
            </Suspense>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default CollectionDetailHistory;
