import React, { useState, Suspense } from 'react';
import Cause from '../../Cause';
import New from '../../New';
import PopularNft from '../../PopularNft';

// Lazy load below-the-fold components
const ComingSoon = React.lazy(() => import('../../ComingSoon'));
const LeaderBoard = React.lazy(() => import('../../LeaderBoard'));
const FriendSuggestion = React.lazy(() => import('../../FriendSuggestion'));

// Simple loading component
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center p-4">
    <div>Loading...</div>
  </div>
);

const SigninHome = () => {
  const [searchQuery, setSearchQuery] = useState("");
console.log("searchQuery",searchQuery)
  return (
    <>
      {/* Keep critical components for immediate render */}
      <Cause />
      <New />
      <PopularNft />
      
      {/* Lazy load components that appear below the fold */}
      <Suspense fallback={<LoadingSpinner />}>
        <ComingSoon />
        <LeaderBoard />
        <FriendSuggestion searchQuery={searchQuery}/>
      </Suspense>
    </>
  );
};

export default SigninHome;
