import {
  AUTH,
  LOGOUT,
  UPDATE_SOCIAL_MEDIA,
  UPDATE_NOTIFICATION_SETTINGS,
} from '../constants';
// eslint-disable-next-line

const initialUserState = {
  id: '',
  walletAddress: '',
  email: '',
  nonProfitName: '',
  einNumber: '',
  emailVerified: '',
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  address: '',
  addressTwo: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  is2faEnabled: '',
  role: '',
  profilePicture: '',
  bannerImage: '',
  bio: '',
  missionStatement:'',
  favourites: '',
  socialMedia: '',
  defaultSponsorshipFee: '',
  fiscalProjects: [],
  fiscalSponsorId: '',
  systemPermissions: [],
  friends: [],
  notificationIds: [],
  isoDocument: '',
  isoGuideStarEmail: '',
  organizationType: '',
  verificationStatus:'',
  isIsoVerified: '',
  categories: '',
  createdAt: '',
};

export const AuthReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case AUTH:
    console.log(action.payload,'action.payloadaction.payload')
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_SOCIAL_MEDIA:
      return {
        ...state,
        socialMedia: action.payload,
      };
    case UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationIds: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        id: '',
        walletAddress: '',
        nonProfitName: '',
        einNumber: '',
        email: '',
        emailVerified: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        address: '',
        addressTwo: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        is2faEnabled: '',
        role: '',
        profilePicture: '',
        bannerImage: '',
        bio: '',
        missionStatement:'',
        favourites: '',
        socialMedia: '',
        defaultSponsorshipFee: '',
        fiscalProjects: [],
        fiscalSponsorId: '',
        systemPermissions: [],
        friends: [],
        notificationIds: [],
        isoDocument: '',
        isoGuideStarEmail: '',
        organizationType: '',
        isIsoVerified: '',
        categories: '',
        createdAt: '',
      };

    default:
      return state;
  }
};
